import React, { useEffect } from "react"
import ReactMarkdown from "react-markdown"
import * as Styled from "./styled"
import { graphql } from "gatsby"
import { useAppStateDispatch } from "../../providers/context-provider/app-context"

export default function PolicyPage({ pageContext }) {
  const appStateDispatch: any = useAppStateDispatch()

  useEffect(() => {
    appStateDispatch({
      type: "activeZoneUserTab",
      value: {
        activeZoneUserTab: "",
      },
    })
  }, [])
  return (
    <Styled.PolicyPageContent>
      <Styled.PolicyTitle>{pageContext.display}</Styled.PolicyTitle>
      <ReactMarkdown className="markdown-content">
        {pageContext.content}
      </ReactMarkdown>
    </Styled.PolicyPageContent>
  )
}

export const query = graphql`
  query PolicyPageQuery($locale: String!) {
    navBarData: allDatoCmsNavigationBar(filter: { locale: { eq: $locale } }) {
      nodes {
        contactLink
        contactText
        locale
        pressLink
        pressText
        zonaBiambideraText
        zonaPlayeraText
        showZonaBiambidera
        showZonaPlayera
        showPress
        showContact
        showSearch
        languageDisplayName
        linkedinUrl
        facebookUrl
        instagramUrl
      }
    }
    footer: allDatoCmsFooter(filter: { locale: { eq: $locale } }) {
      nodes {
        links {
          name
          uri
        }
      }
    }
  }
`
