import styled from "styled-components"

export const PolicyPageContent = styled.main`
  max-width: var(--content-max-width);
  margin: auto;
  margin-top: calc(var(--nav-height) + var(--nav-social-height));
  padding: 1.375rem 1rem 1rem 1rem;
`

export const PolicyTitle = styled.h1`
  background: #ffffff;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  border-radius: 0.625rem;
  max-width: var(--content-max-width);
  margin: 1rem auto 2.5rem auto;
  padding: 1rem 2rem;
  color: var(--color-primary-dark);
  text-align: center;
`
